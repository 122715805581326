<template>
  <div>
    <div class="button-container">
      <InlineMessageBox header="Create an Event">
        <h1 style="visibility:hidden; height: 0px; margin: 0">Events Dashboard</h1>
        <p>
          Ready to create an event? We guide you through the entire process and
          if you hit any snags you can always message us on live chat for help.
          Don't forget to hit submit on the last step once you've included all
          of your info and every time you make an edit.
        </p>
        <diyobo-button
          txt="Let's Create Something Awesome!"
          @click="toCreateEvent"
        />
      </InlineMessageBox>
    </div>
    <div class="page-tabs">
      <tab-tote
        :tabs="navTabs"
        :value="navTabSelected"
        v-on:click="onClickNavTab"
      />
      <div class="divider" />
    </div>
    <router-view />
  </div>
</template>

<script>
import TabTote from "@/components/TabTote.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import InlineMessageBox from "@/components/InlineMessageBox.vue";

export default {
  name: "event-dashboard",
  components: {
    DiyoboButton,
    TabTote,
    InlineMessageBox
  },
  head() {
    return {
      title: "Event"
    };
  },
  data() {
    return {
      navTabs: ["Current Live Events", "Previous Events"]
    };
  },
  computed: {
    navTabSelected() {
      const path = this.$route.path;
      if (path.endsWith("/current-events")) {
        return 0;
      } else if (path.endsWith("previous-events")) {
        return 1;
      }
    }
  },
  created() {
    this.$store.commit("setTitle", "Dashboard");
    // this.$axios.post("/invites").then(response => {
    //   const data = response.data;
    //   this.invites = data.invites;
    // });
  },
  mounted() {
    this.$store.dispatch(
      "updateHelpVideoSrc",
      "https://www.youtube.com/embed/gmUkPdOhC7A"
    );
  },

  methods: {
    onClickNavTab(index) {
      const path = this.$route.path;
      if (index === 0 && !path.endsWith("/current-events")) {
        this.$router.push(`/dashboard/current-events`);
      } else if (index === 1 && !path.endsWith("/previous-events")) {
        this.$router.push(`/dashboard/previous-events`);
      }
    },
    toCreateEvent() {
      if (
        this.$store.state.user.plannerPayouts < 1 &&
        this.$store.state.user.plannerAddedBrands === 0
      ) {
        let activeBrand = this.$store.state.activeBrand || null;

        let subdomain = activeBrand ? activeBrand.subdomain : "";
        this.$router.push(`/brands/payout/${subdomain}`);
      } else {
        this.$router.push({ name: "create-event" });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.button-container {
  margin: 18px auto;
  display: flex;
  justify-content: center;
  width: 94%;
  max-width: 1100px;
  &::v-deep .info-text {
    .info-text-inner {
      padding: 20px;
      .info-left {
        span {
          margin: auto;
          font-size: 24px;
        }
        p {
          font-size: 15px;
          text-align: center;
        }
        button {
          background: var(--primary-green);
          color: #fff;
          border-color: #5fb469;
          width: 50%;
          margin: auto;
          font-size: 1em;
          padding: 10px 12px;

          @media only screen and (max-width: 900px) {
            width: 70%;
          }
          @media only screen and (max-width: 700px) {
            width: 85%;
          }
        }
      }
    }
  }
}
.page-tabs {
  margin: auto;
  width: 94%;
  max-width: 1100px;
}
</style>
